import React, {useEffect} from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
// Import the images shown in your screenshots
import heroImage from "../images/eps-web-header.jpg" // Image of man walking with child
import oneInEightImage from "../images/1-in-8-web-circle.png" // Circular image with 1 in 8 man
import patientImage from "../images/patient-web-circle.png" // Patient circular image
import physicianImage from "../images/physician_web_circle.png" // Physician image
import blueCheckmark from "../images/blue_check_mark.png" // Blue checkmark image for the title
// Add these new imports for the benefit icons
import noScriptIcon from "../images/no-script-icon.png" // No doctor referral
import over45Icon from "../images/over-45.png" // For men 45+
import examIcon from "../images/10-15-exam.png" // Quick exam
import hoursIcon from "../images/12-hours.png" // 12 hours prep
import nonInvasiveIcon from "../images/non-invasive.png" // Non-invasive
import HeroImageContainer from "../components/heroImage"
import HearFromYouForm from "../components/forms/hearfromyou"

// SVG icons for the feature points
// const CheckmarkIcon = () => ( // Commented out since it's not used
//   <svg className="checkmark-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="12" cy="12" r="11" stroke="#3498db" strokeWidth="2"/>
//     <path d="M7 12L10 15L17 8" stroke="#3498db" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//   </svg>
// );

let title = `Enhanced Prostate Screening | Prostate MRI | RadNet Imaging`
let desc = `Enhanced prostate screening for men, age 45 and older. Don't wait - your health is worth it. Early detection of prostate cancer through advanced MRI technology.`

export const EnhancedProstateScreening = ({data}) => {
    useEffect(() => {
        // For accordion functionality if needed
        const accordions = document.getElementsByClassName("accordion");
        for (let i = 0; i < accordions.length; i++) {
            accordions[i].addEventListener("click", function() {
                this.classList.toggle("active");
                const panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }

        // Cleanup function
        return () => {
            for (let i = 0; i < accordions.length; i++) {
                const oldElement = accordions[i];
                const newElement = oldElement.cloneNode(true);
                oldElement.parentNode.replaceChild(newElement, oldElement);
            }
        };
    }, []);

    return (
        <>
            <Layout page={title} desc={desc} pageClass={"EPS"} >
                <div className="inside-page prostate-screening pb2">
                    {/* Hero section */}
                    <div className="hero">
                        <div className="overlay">
                            <div className="container-inner-xs">
                                {/* Content will be overlaid on the hero image by CSS */}
                            </div>
                        </div>
                        <HeroImageContainer imageUrl={heroImage} />
                    </div>
                    
                    {/* Main header section */}
                    <div className="eps-header-section">
                        <div className="container-inner">
                            <h1 className="eps-heading">
                                <span className="title-line">ENHANCED</span>
                                <span className="title-line">PR<img src={blueCheckmark} alt="checkmark" className="title-checkmark"/>STATE</span>
                                <span className="title-line">SCREENING</span>
                            </h1>
                            <h2 className="eps-tagline">
                                For men, age 45 and older. Don't wait – your health is worth it.
                            </h2>
                        </div>
                    </div>
                    
                    {/* 1 in 8 Statistics Section */}
                    <div className="container-inner pt4">
                        <div className="one-in-eight-section">
                            <div className="row">
                                <div className="col-md-5 col-sm-12 text-center">
                                    <img className="circular-image" src={oneInEightImage} alt="1 in 8 men statistic" />
                                </div>
                                <div className="col-md-7 col-sm-12 p50">
                                    <div className="statistics-content">
                                        <p className="statistic">
                                            About 1 in 8 men will be diagnosed with prostate cancer during their lifetime - it is 
                                            the most common cancer in men in the United States (not including skin cancer).<sup>1</sup>
                                        </p>
                                        <p className="statistic">
                                            We are leading the way in early prostate cancer detection, leveraging advanced 
                                            imaging and diagnostics to ensure earlier, more accurate detection and better outcomes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="intro-content">
                            <p>
                                Whether you're concerned about your prostate health, have a family history of prostate cancer, or simply want 
                                to take charge of your well-being, Enhanced Prostate Screening is a proactive step you can take today.
                            </p>
                            <p className="early-detection">
                                <h4>Early detection saves lives - Prioritizing your health now means peace of mind for the future.</h4>
                            </p>
                            <p>
                                Enhanced Prostate Screening (EPS) leverages DeepHealth's, FDA cleared, AI-powered prostate solution to 
                                deliver early and accurate cancer detection.
                            </p>
                        </div>
                        
                        {/* Benefits Section */}
                        <div className="benefits-section">
                            <div className="benefit-row">
                                <div className="benefit-item">
                                    <div className="benefit-icon">
                                        <img src={noScriptIcon} alt="No Doctor Referral" />
                                    </div>
                                    <div className="benefit-text">
                                        No Doctor Referral Required
                                    </div>
                                </div>
                                <div className="benefit-item">
                                    <div className="benefit-icon">
                                        <img src={hoursIcon} alt="Minimal Prep" />
                                    </div>
                                    <div className="benefit-text">
                                        Minimal Prep Required
                                    </div>
                                </div>
                            </div>
                            
                            <div className="benefit-row">
                                <div className="benefit-item">
                                    <div className="benefit-icon">
                                        <img src={nonInvasiveIcon} alt="Non-Invasive" />
                                    </div>
                                    <div className="benefit-text">
                                        Non-Invasive and No IV Contrast
                                    </div>
                                </div>
                                <div className="benefit-item">
                                    <div className="benefit-icon">
                                        <img src={over45Icon} alt="For Men 45+" />
                                    </div>
                                    <div className="benefit-text">
                                        For Men, age 45 and Older
                                    </div>
                                </div>
                            </div>
                            
                            <div className="benefit-row">
                                <div className="benefit-item">
                                    <div className="benefit-icon">
                                        <img src={examIcon} alt="Quick Exam" />
                                    </div>
                                    <div className="benefit-text">
                                        10–15 Minute Exam
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* For Patients Section */}
                    <div className="patients-section pt4">
                        <div className="container-inner">
                            <h2 className="section-heading">For Patients</h2>
                            
                            {/* First row: Screening description and candidacy with image */}
                            <div className="patient-flex-container">
                                <div className="patient-content">
                                    <h3 className="subsection-title">How Does Enhanced Prostate Screening Work?</h3>
                                    <p>
                                        Prostate MRI (magnetic resonance imaging) is an advanced imaging technique that uses a large magnet, 
                                        radio waves and a computer to produce detailed pictures of the structures inside the prostate gland.
                                    </p>
                                    <p>
                                        Enhanced Prostate Screening (EPS) leverages DeepHealth's, FDA cleared, AI-powered prostate solution 
                                        to deliver early and accurate cancer detection.
                                    </p>
                                    
                                    <h3 className="subsection-title">Am I a Candidate for Enhanced Prostate Screening?</h3>
                                    <div className="patient-checklist">
                                        <div className="patient-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Men aged 45 and older who have not had a previous prostate biopsy or prostate cancer diagnosis</p>
                                        </div>
                                        <div className="patient-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>PSA is not required but however it is recommended</p>
                                        </div>
                                        <div className="patient-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>If PSA is 10 or greater, EPS is not a recommended exam</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="patient-image">
                                    <div className="image-container">
                                        <img src={patientImage} alt="Patient" />
                                    </div>
                                </div>
                            </div>
                            
                            {/* Second row: Prep instructions section (full width) */}
                            <div className="prep-section">
                                <h3 className="subsection-title">What is the Prep for Enhanced Prostate Screening?</h3>
                                <div className="patient-checklist">
                                    <div className="patient-checklist-item">
                                        <div className="blue-check-icon">
                                            <img src={blueCheckmark} alt="Checkmark" />
                                        </div>
                                        <p>Begin a clear liquid diet starting 8 hours prior to your appointment. Clear liquids include water, soup broth, Jello, hot/cold tea, Gatorade, and water-based popsicles.</p>
                                    </div>
                                    <div className="patient-checklist-item">
                                        <div className="blue-check-icon">
                                            <img src={blueCheckmark} alt="Checkmark" />
                                        </div>
                                        <p>Take 2 Gas-X 125mg soft gels 25 minutes prior to your scheduled appointment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* For Physicians Section */}
                    <div className="container-inner pt4">
                        <div className="physicians-section">
                            <h2 className="section-heading">For Physicians</h2>
                            
                            {/* Row 1: Intro and Early Detection with Image */}
                            <div className="physician-flex-container">
                                <div className="physician-content">
                                    <p>
                                        For patients concerned about their prostate health, have a family history of prostate cancer, 
                                        or simply want to take charge of their well-being, Enhanced Prostate Screening is a proactive 
                                        step they can take today.
                                    </p>
                                    
                                    <h3 className="subsection-title">Early Detection Saves Lives</h3>
                                    <p>
                                        Enhanced Prostate Screening (EPS) leverages DeepHealth's, FDA cleared, AI-powered prostate solution 
                                        to deliver early and accurate cancer detection.
                                    </p>
                                    
                                    <div className="physician-checklist">
                                        <div className="physician-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Non-invasive and no IV contrast</p>
                                        </div>
                                        <div className="physician-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>10–15 minute exam</p>
                                        </div>
                                        <div className="physician-checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Minimal prep required</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="physician-image">
                                    <div className="image-container">
                                        <img src={physicianImage} alt="Physician" />
                                    </div>
                                </div>
                            </div>
                            
                            {/* Row 2: Advancing prostate cancer detection */}
                            <div className="physician-row">
                                <h3 className="subsection-title">Advancing prostate cancer detection with innovative imaging and superior expertise</h3>
                                <p>
                                    Recent Studies have shown that integrating MRI into prostate screening protocol results in a reduced number 
                                    of unnecessary biopsies and overdiagnosis of insignificant prostate cancer, while maintaining detection of 
                                    clinically significant prostate cancer, as compared with PSA-only screening.<sup>2</sup>
                                </p>
                            </div>
                            
                            {/* Row 3: Who is a Candidate */}
                            <div className="physician-row">
                                <h3 className="subsection-title">Who is a Candidate for Enhanced Prostate Screening?</h3>
                                <div className="physician-checklist">
                                    <div className="physician-checklist-item">
                                        <div className="blue-check-icon">
                                            <img src={blueCheckmark} alt="Checkmark" />
                                        </div>
                                        <p>Men aged 45 and older who have not had a previous prostate biopsy or prostate cancer diagnosis</p>
                                    </div>
                                    <div className="physician-checklist-item">
                                        <div className="blue-check-icon">
                                            <img src={blueCheckmark} alt="Checkmark" />
                                        </div>
                                        <p>PSA is not required but however it is recommended</p>
                                    </div>
                                    <div className="physician-checklist-item">
                                        <div className="blue-check-icon">
                                            <img src={blueCheckmark} alt="Checkmark" />
                                        </div>
                                        <p>If PSA is 10 or greater, EPS is not a recommended exam</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contact Section now outside the main container */}
                <div className="contact-section-wrapper">
                    {/* Why Choose Us Section */}
                    <div>
                        <div className="why-choose-us-section mb6">
                            <div className="dotted-bg-container">
                                <div className="dotted-bg-inner-wrapper">
                                    <h2 className="section-heading">Why Choose Us?</h2>
                                    <p className="section-intro">
                                        We were one of the first radiology practices in the United States to establish a dedicated Prostate Program. 
                                        Our Prostate Program has performed more prostate MRI exams than any group in the country, with a level of 
                                        accuracy that meets or exceeds top academic centers worldwide.
                                    </p>
                                    
                                    <div className="why-choose-checklist">
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>We are a trusted partner for Urologists and healthcare providers.</p>
                                        </div>
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Our patient-centered approach means your comfort and care come first.</p>
                                        </div>
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Our team of specialty trained board-certified prostate radiologists are leaders in prostate imaging.</p>
                                        </div>
                                    </div>
                                    
                                    <p className="plus-heading">Plus</p>
                                    
                                    <div className="why-choose-checklist">
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Accurate and timely results</p>
                                        </div>
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Online access to reports and images</p>
                                        </div>
                                        <div className="checklist-item">
                                            <div className="blue-check-icon">
                                                <img src={blueCheckmark} alt="Checkmark" />
                                            </div>
                                            <p>Quick appointment turnaround</p>
                                        </div>
                                    </div>
                                    
                                    <p className="pricing-note">
                                        This screening exam is not yet covered by insurance. Cost is $275.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-section-container">
                        <HearFromYouForm />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
    query EnhancedProstateScreeningQuery {
        allNodePage(sort: {order: ASC, fields: created}) {
            nodes {
                title
                fields {
                    slug
                }
            }
        }
    }
`

export default EnhancedProstateScreening